import React from 'react';
import Layout from 'components/Layout';
import Container from 'components/Container';
import Seo from "../components/Seo";
import logomark from '../images/logomark.svg';
import resume from "../images/justin-armburger-resume.pdf";
import diamond from "../images/diamond.svg";
import diamondBlue from "../images/diamond-blue.svg";
import diamondYellow from "../images/diamond-yellow.svg";
import eleanor from "../images/eleanor.png";
import justin from "../images/justin.png";

const SecondPage = () => {
  return (
    <Layout pageName="about">
        <Seo
                title={'About'}
        />
      <Container>
          <div className="grid grid-cols-1 gap-32">
              <div className="grid md:grid-cols-6 gap-10 content-center">
                  <div className="col-span-6 md:col-span-3 relative">
                      <img src={justin} alt="Cannabis Dispensary" className=""/>
                  </div>
                  <div className="text-left col-span-6 md:col-span-3 lg:col-span-2">
                      <h1 className="font-serif text-7xl">Extra, extra!</h1>
                      <h2 className="font-serif text-4xl mt-4">Read all about me!</h2>
                      <img src={logomark} alt="Justin Armburger" className="mt-6"/>
                      <p className="mt-12">I’m an art director and designer based in Cleveland, Ohio.</p>
                      <p className="mt-4">I'm also an adjunct professor at Kent State University, where I teach Magazine Design. I previously taught Public Relations Publications, which teaches PR students how to apply theory and concepts to create practical, efficient design.</p>
                      <a href={resume} className="font-mono bg-black text-white px-8 py-4 inline-block hover:text-pink transition-colors duration-200 mt-8" target="_blank" rel="noreferrer">Download Resume</a>
                  </div>
              </div>
              <div className="">
                  <h2 className="font-serif text-5xl mt-4">Things that make my mom proud.</h2>
                  <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-10 mt-12">
                      <div className="bg-pink p-8 text-center">
                          <h3 className="block font-serif text-4xl text-white">13 National Awards</h3>
                          <h4 className="text-white mt-4 text-lg">American Society of Business Publication Editors</h4>
                      </div>
                      <div className="bg-yellow p-8 text-center">
                          <h3 className="block font-serif text-4xl text-white">Best New Publication</h3>
                          <h4 className="text-white mt-4 text-lg">Cannabis Dispensary, ASBPE & Folio</h4>
                      </div>
                      <div className="bg-blue p-8 text-center">
                          <h3 className="block font-serif text-4xl text-white">Best Magazine Design</h3>
                          <h4 className="text-white mt-4 text-lg">Cannabis Dispensary, ASBPE</h4>
                      </div>
                      <div className="bg-yellow p-8 text-center">
                          <h3 className="block font-serif text-4xl text-white">2018 Member</h3>
                          <h4 className="text-white mt-4 text-lg">Folio Top 100 Class</h4>
                      </div>
                      <div className="bg-blue p-8 text-center">
                          <h3 className="block font-serif text-4xl text-white">11 years</h3>
                          <h4 className="text-white mt-4 text-lg">In the design/publishing industries</h4>
                      </div>
                      <div className="bg-pink p-8 text-center">
                          <h3 className="block font-serif text-4xl text-white">5 years</h3>
                          <h4 className="text-white mt-4 text-lg">Adjunct Professor at Kent State University, Magazine Design</h4>
                      </div>
                  </div>
              </div>
              <div className=" p-8 border">
                  <h2 className="font-serif text-5xl mt-4">Past jobs that have nothing to do with design.</h2>
                  <div className="mt-12 md:flex justify-center content-between">
                      <div className="text-center align-center items-center flex justify-center flex-col md:w-1/4">
                          <img src={diamond} alt="Cannabis Dispensary"/>
                          <h4 className="text-lg mt-2">Hot dog line cook</h4>
                      </div>
                      <div className="text-center align-center items-center flex justify-center flex-col mt-8 md:mt-0 md:w-1/4">
                          <img src={diamondBlue} alt="Cannabis Dispensary"/>
                          <h4 className="text-lg mt-2">Nude model</h4>
                      </div>
                      <div className="text-center align-center items-center flex justify-center flex-col mt-8 md:mt-0 md:w-1/4">
                          <img src={diamondYellow} alt="Cannabis Dispensary"/>
                          <h4 className="text-lg mt-2">Door-to-door salesman</h4>
                      </div>
                  </div>
              </div>
              <div className="text-left grid md:grid-cols-6">
                  <div className="col-span-3 bg-black p-16 flex justify-center flex-col mr-0.5">
                      <h2 className="font-serif text-8xl text-white">Say hey!</h2>
                      <a href="mailto:jarmburg@gmail.com" className="font-mono text-2xl block mt-8 text-white contact-info hover:text-pink transition-colors duration-200">jarmburg@gmail.com</a>
                  </div>
                  <div className="col-span-3 md:-ml-28 relative">
                      <img src={eleanor} alt=""/>
                  </div>
              </div>
          </div>
      </Container>
    </Layout>
  );
};

export default SecondPage;